import React from "react";
import { createContext, ReactNode, useEffect, useReducer } from "react";

import { ActionMap, AmplifyContextType } from "../types/auth";

import useAppDispatch from "../hooks/useAppDispatch";
import { cognitoConfig } from "../config";
import { Amplify } from "aws-amplify";
import { fetchAuthSession, JWT } from "aws-amplify/auth";
import { signIn, signOut, confirmSignUp } from "aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import { User, VisiblyGroups } from "../features/user/models/user";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getUser,
  setAppEntryLocation,
} from "../features/user/slices/userSlice";
import { resetStore } from "../redux/store";
import * as Sentry from "@sentry/react";
import { getEnvironmentDetails } from "../features/account/slices/environmentSlice";
import useAppSelector from "../hooks/useAppSelector";

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";
const SIGN_IN = "SIGN_IN";
const SET_CURRENT_ORG_ID = "SET_CURRENT_ORG_ID";

type AmplifyAuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  userId?: string;
  isVisiblyAdmin: boolean;
  isVisiblySupport: boolean;
  isVisiblyPracticalReviewer: boolean;
  isVisiblyExternalPartner: boolean;
  isVisiblyLearningDesigner: boolean;
  isVisiblyExperiment: boolean;
  currentOrgId?: string;
};

const initialState: AmplifyAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  userId: undefined,
  isVisiblyAdmin: false,
  isVisiblySupport: false,
  isVisiblyPracticalReviewer: false,
  isVisiblyExternalPartner: false,
  isVisiblyLearningDesigner: false,
  isVisiblyExperiment: false,
  currentOrgId: undefined,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    idToken?: JWT;
    currentOrgId?: string;
  };
  [SIGN_OUT]: undefined;
  [SIGN_IN]: {
    idToken?: JWT;
  };
  [SET_CURRENT_ORG_ID]: {
    id: string;
  };
};

type AmplifyActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const getUserIdFromIdToken = (idToken?: JWT) => {
  return idToken?.payload["cognito:username"]?.toString();
};

const getGroupsFromIdToken = (idToken?: JWT) => {
  const groups = idToken?.payload["cognito:groups"] ?? [];

  return Array.isArray(groups) ? groups.map((group) => group!.toString()) : [];
};

export const getCurrentOrgId = () => {
  return localStorage.getItem("currentOrgId") || undefined;
};

const identityPosthog = (user: User) => {
  window.posthog.identify(user.id, {
    userId: user.id,
    email: user.email,
    orgIds: user.orgs?.map((element) => element.id),
    createdAt: user.createdAt,
    hasOrgs: user.orgs?.length > 0,
    numberOfOrgs: user.orgs?.length,
  });
};

const identitySentry = (userId?: string) => {
  Sentry.setUser(
    userId
      ? {
          id: userId,
        }
      : null
  );

  Sentry.setUser(null);
};

const identifyWithServices = (user: User) => {
  identitySentry(user.id);
  identityPosthog(user);
};

const unidentifyWithServices = () => {
  identitySentry();
  window.posthog.reset();
};

const reducer = (state: AmplifyAuthState, action: AmplifyActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, idToken, currentOrgId } = action.payload;

    const userId = getUserIdFromIdToken(idToken);
    const groups = getGroupsFromIdToken(idToken);

    const isVisiblyAdmin = !!groups.includes(
      VisiblyGroups["visibly-admin-group"]
    );
    const isVisiblySupport = !!groups.includes(
      VisiblyGroups["visibly-support-group"]
    );
    const isVisiblyPracticalReviewer = !!groups.includes(
      VisiblyGroups["visibly-learning-practical-reviewer-group"]
    );
    const isVisiblyExternalPartner = !!groups.includes(
      VisiblyGroups["visibly-external-partner-group"]
    );

    const isVisiblyLearningDesigner = !!groups.includes(
      VisiblyGroups["visibly-learning-designer-group"]
    );
    const isVisiblyExperiment = !!groups.includes(
      VisiblyGroups["visibly-experiment-group"]
    );

    return {
      ...state,
      isAuthenticated,
      userId,
      isVisiblyAdmin,
      isVisiblySupport,
      isVisiblyPracticalReviewer,
      isVisiblyExternalPartner,
      isVisiblyLearningDesigner,
      isVisiblyExperiment,
      isInitialized: true,
      currentOrgId,
    };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: undefined,
      isVisiblyAdmin: false,
      isVisiblySupport: false,
      isVisiblyPracticalReviewer: false,
      isVisiblyExternalPartner: false,
      isVisiblyLearningDesigner: false,
      isVisiblyExperiment: false,
      currentOrgId: undefined,
    };
  }
  if (action.type === SIGN_IN) {
    const { idToken } = action.payload;

    const userId = getUserIdFromIdToken(idToken);
    const groups = getGroupsFromIdToken(idToken);

    const isVisiblyAdmin = !!groups.includes(
      VisiblyGroups["visibly-admin-group"]
    );
    const isVisiblySupport = !!groups.includes(
      VisiblyGroups["visibly-support-group"]
    );
    const isVisiblyPracticalReviewer = !!groups.includes(
      VisiblyGroups["visibly-learning-practical-reviewer-group"]
    );
    const isVisiblyExternalPartner = !!groups.includes(
      VisiblyGroups["visibly-external-partner-group"]
    );
    const isVisiblyLearningDesigner = !!groups.includes(
      VisiblyGroups["visibly-learning-designer-group"]
    );
    const isVisiblyExperiment = !!groups.includes(
      VisiblyGroups["visibly-experiment-group"]
    );

    return {
      ...state,
      isAuthenticated: true,
      userId,
      isVisiblyAdmin,
      isVisiblySupport,
      isVisiblyPracticalReviewer,
      isVisiblyExternalPartner,
      isVisiblyLearningDesigner,
      isVisiblyExperiment,
    };
  }
  if (action.type === SET_CURRENT_ORG_ID) {
    const { id } = action.payload;

    return {
      ...state,
      currentOrgId: id,
    };
  }
  return state;
};

const AuthContext = createContext<AmplifyContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const environmentDetails = useAppSelector(
    (state) => state.environment.environmentDetails
  );

  if (environmentDetails === null) {
    appDispatch(getEnvironmentDetails());
  }

  const initialize = async () => {
    appDispatch(setAppEntryLocation(location));

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: cognitoConfig.userPoolId,
          userPoolClientId: cognitoConfig.clientId,
          identityPoolId: cognitoConfig.identityPoolId,
        },
      },
    });

    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};

      const userId = (await getCurrentUser()).username;
      const currentOrgId = getCurrentOrgId();
      const user = (await appDispatch(getUser(userId)).unwrap()).data;
      identifyWithServices(user);

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: true,
          idToken,
          currentOrgId,
        },
      });
    } catch (error) {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const signInAsync = async (email: any, password: any) => {
    await signIn({
      username: email,
      password: password,
    });
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    const userId = (await getCurrentUser()).username;
    const user = (await appDispatch(getUser(userId)).unwrap()).data;
    identifyWithServices(user);

    dispatch({
      type: SIGN_IN,
      payload: {
        idToken,
      },
    });
  };

  const signOutAsync = async () => {
    await signOut();
    await appDispatch(resetStore());
    localStorage.removeItem("currentOrgId");
    dispatch({ type: SIGN_OUT });
    unidentifyWithServices();
    navigate("/");
  };

  const verifyAccount = async (username: string, code: string) => {
    if (!state.isInitialized) {
      initialize();
    }

    await confirmSignUp({ username: username, confirmationCode: code }).catch(
      (error) => {
        if (
          error.message ===
          "User cannot be confirmed. Current status is CONFIRMED"
        ) {
          return;
        }
        throw error;
      }
    );
  };

  const setCurrentOrgId = (id: string) => {
    localStorage.setItem("currentOrgId", id);

    dispatch({
      type: SET_CURRENT_ORG_ID,
      payload: {
        id: id,
      },
    });
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {};

  if (!state.isInitialized) {
    return <React.Fragment />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "amplify",
        signIn: signInAsync,
        signUp,
        signOut: signOutAsync,
        verifyAccount,
        setCurrentOrgId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
