import React, { Suspense, useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import GlobalStyle from "../../components/GlobalStyle";
import Sidebar from "../../components/sidebar/Sidebar";
import { SidebarItemsType, SidebarSectionType } from "../../types/sidebar";
import { Table, Users, Box as BoxIcon } from "react-feather";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/Loader";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

interface DashboardType {
  children?: React.ReactNode;
}

const ExperimentDashboard: React.FC<DashboardType> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={navSections}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={navSections}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <Suspense fallback={<Loader />}>
          <MainContent p={isLgUp ? 12 : 5}>
            {children}
            <Outlet />
          </MainContent>
        </Suspense>
      </AppContent>
    </Root>
  );
};

const complianceSection = [
  {
    href: "/experiment/direct-workforce",
    icon: Users,
    pageTitle: () => "Direct workforce",
  },
  {
    href: "/experiment/groups",
    icon: BoxIcon,
    pageTitle: () => "Groups",
  },
] as SidebarItemsType[];

const librariesSection = [
  {
    href: "/experiment/libraries/roles",
    icon: Table,
    pageTitle: () => "Roles",
  },
  {
    href: "/experiment/libraries/accreditations",
    icon: Table,
    pageTitle: () => "Accreditations",
  },
] as SidebarItemsType[];

const navSections = [
  {
    title: "Compliance",
    pages: complianceSection,
    wrapperElement: (key, children) => {
      return <>{children}</>;
    },
  },
  {
    title: "Libraries",
    pages: librariesSection,
    wrapperElement: (key, children) => {
      return <>{children}</>;
    },
  },
] as SidebarSectionType[];

export default ExperimentDashboard;
