import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../models/apiResponseMeta";
import {
  AccreditationUser,
  UserRoleCompliance,
} from "../models/accreditationUser";
import axios from "../../utils/axios";
import { QueryRoleComplianceRequest } from "../slices/accreditationUserSlice";

type BulkImportRequest = {
  csvImportId: string;
  orgId: string;
};

type BulkImportResponse = {
  meta: ApiResponseMeta;
  data: AccreditationUser[];
};

export async function importUsers(
  request: BulkImportRequest
): Promise<AxiosPromise<BulkImportResponse>> {
  return await axios.post(`accreditations/users/v1/import-from-csv`, request);
}

type QueryRoleComplianceResponse = {
  meta: ApiResponseMeta;
  data: UserRoleCompliance[];
};

export async function queryRoleCompliance(
  request: QueryRoleComplianceRequest
): Promise<AxiosPromise<QueryRoleComplianceResponse>> {
  return await axios.post(`accreditations/users/v1/query-compliance`, request);
}
