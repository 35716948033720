import { CheckCircle } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";

export const checkInsSection = [
  {
    href: "/check-ins",
    icon: CheckCircle,
    pageTitle: () => "Duty of care",
    innerNavigationItems: [
      {
        title: "Dashboard",
        to: (_) => "/check-ins/dashboard",
      },
      {
        title: "Check-ins",
        to: (_) => "/check-ins/checks",
      },
      {
        title: "Analytics",
        to: (_) => "/check-ins/analytics/user-activity",
      },
    ],
  },
] as SidebarItemsType[];
