import { AxiosPromise } from "axios";
import { ApiResponseMeta } from "../../models/apiResponseMeta";
import { OrganisationMember } from "../models/organisationMember";
import { QueryOrganisationMembersRequest } from "../slices/organisationMembersSlice";
import axios from "../../utils/axios";

type QueryOrganisationMembersResponse = {
  data: OrganisationMember[];
  meta: ApiResponseMeta;
};

async function queryOrganisationMembers(
  request: QueryOrganisationMembersRequest
): Promise<AxiosPromise<QueryOrganisationMembersResponse>> {
  return await axios.post(`/orgs/v1/${request.orgId}/members/query`, request);
}

export { queryOrganisationMembers };
