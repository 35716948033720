import { Navigate, RouteObject, useParams } from "react-router-dom";
import VisiblyOSLayout from "../visiblyOS/VisiblyOSLayout";
import { lazy, ReactElement, useContext, useEffect } from "react";
import CanViewCapabilityRouteGuard from "../components/guards/CanViewCapabilityRouteGuard";
import useAppDispatch from "../hooks/useAppDispatch";
import { PageHeaderContext } from "../visiblyOS/PageHeaderContext";
import { getOrganisationMemberDetails } from "../features/user/slices/userOrganisationMemberSlice";
import { Worker } from "../visiblyOS/models/worker";
import { getCurrentOrgId } from "../contexts/AmplifyContext";

const DirectWorkforceAccreditations = lazy(
  () => import("../visiblyOS/features/workforce/pages/Accreditations")
);

const DirectWorkforceWorkers = lazy(
  () => import("../visiblyOS/features/workforce/pages/Workers")
);

const Dashboard = lazy(
  () => import("../visiblyOS/features/workforce/pages/Dashboard")
);

const WorkerDetails = lazy(
  () => import("../visiblyOS/features/workforce/pages/workerDetails")
);

const WorkerWorkerRoles = lazy(
  () =>
    import("../visiblyOS/features/workforce/pages/workerDetails/WorkerRoles")
);

const WorkerAccreditations = lazy(
  () =>
    import("../visiblyOS/features/workforce/pages/workerDetails/Accreditations")
);

const WorkerRoles = lazy(
  () => import("../visiblyOS/features/workforce/pages/WorkerRoles")
);

const GroupRoles = lazy(
  () => import("../visiblyOS/features/workforce/pages/GroupRoles")
);

export const workforceRoutes = [
  {
    path: "compliance",
    element: (
      <CanViewCapabilityRouteGuard capability="accreditations">
        <VisiblyOSLayout />
      </CanViewCapabilityRouteGuard>
    ),
    children: [
      {
        path: "workforce",
        children: [
          {
            path: "",
            element: <Navigate to="/compliance/workforce/dashboard" />,
          },
          {
            path: "accreditations",
            element: <DirectWorkforceAccreditations />,
          },
          {
            path: "workers",
            children: [
              {
                path: "",
                element: <DirectWorkforceWorkers />,
              },
              {
                path: ":workerId",
                children: [
                  {
                    path: "",
                    element: <NavigateToWorkforceHome />,
                  },
                  {
                    path: "details",
                    element: (
                      <WorkerWrapper>
                        <WorkerDetails />
                      </WorkerWrapper>
                    ),
                  },
                  {
                    path: "worker-roles",
                    element: (
                      <WorkerWrapper>
                        <WorkerWorkerRoles />
                      </WorkerWrapper>
                    ),
                  },
                  {
                    path: "accreditations",
                    element: (
                      <WorkerWrapper>
                        <WorkerAccreditations />
                      </WorkerWrapper>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "worker-roles",
            element: <WorkerRoles />,
          },
          {
            path: "group-roles",
            element: <GroupRoles />,
          },
        ],
      },
    ],
  },
] as RouteObject[];

type WorkerWrapperProps = {
  children: ReactElement;
};

function WorkerWrapper(props: WorkerWrapperProps) {
  const dispatch = useAppDispatch();
  const { workerId } = useParams();
  const { worker, setWorker, setButton } = useContext(PageHeaderContext);
  const currentOrgId = getCurrentOrgId();

  useEffect(() => {
    if (workerId && (!worker || workerId !== worker.id) && currentOrgId) {
      dispatch(
        getOrganisationMemberDetails({
          userId: workerId,
          orgId: currentOrgId,
        })
      )
        .unwrap()
        .then((value) => {
          setWorker!({
            id: value.data.userId,
            name: `${value.data.firstNames} ${value.data.lastName}`,
            phoneNumber: value.data.contactInfo?.phoneNumbers[0]?.phoneNumber,
            email: value.data.inOrgEmail,
            postcode: value.data.postcode,
            dateOfBirth: value.data.dateOfBirth,
          } as Worker);
        });
    }
  }, [worker, workerId, setWorker, dispatch, setButton, currentOrgId]);

  return <>{worker && workerId === worker.id ? props.children : null}</>;
}

function NavigateToWorkforceHome() {
  const { workerId } = useParams();

  return <Navigate to={`/compliance/workforce/workers/${workerId}/details`} />;
}
