import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { uploadFileToFileStorage } from "../../services/s3ClientStorageService";
import * as accreditationUserService from "../services/accreditationUserService";
import { LoadingStatus } from "../../models/loadingStatus";
import {
  AccreditationUser,
  UserRoleCompliance,
} from "../models/accreditationUser";

type ImportUserCsvRequest = {
  orgId: string;
  file: File;
};

export const importUsersCsv = createAsyncThunk(
  "accreditationUser/importUsersCsv",
  async (request: ImportUserCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );
      return (
        await accreditationUserService.importUsers({
          orgId: request.orgId,
          csvImportId: importId,
        })
      ).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

export type QueryRoleComplianceRequest = {
  roleId: string;
  groupId: string;
  orgId: string;
};

export const queryRoleCompliance = createAsyncThunk(
  "accreditationUser/queryRoleCompliance",
  async (request: QueryRoleComplianceRequest, { rejectWithValue }) => {
    try {
      return (await accreditationUserService.queryRoleCompliance(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type AccreditationUserState = {
  status: LoadingStatus;
  bulkImportResponse: AccreditationUser[];
  roleCompliances: UserRoleCompliance[];
  error: string | null;
};

const initialState: AccreditationUserState = {
  status: LoadingStatus.idle,
  bulkImportResponse: [],
  roleCompliances: [],
  error: null,
};

const accreditationUserSlice = createSlice({
  name: "accreditationUser",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importUsersCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importUsersCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        bulkImportResponse: action.payload.data,
      };
    });
    builder.addCase(importUsersCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(queryRoleCompliance.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryRoleCompliance.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        roleCompliances: action.payload.data,
      };
    });
    builder.addCase(queryRoleCompliance.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationUserSlice.actions;

export default accreditationUserSlice.reducer;
