import { Navigate, RouteObject } from "react-router-dom";
import ExperimentDashboard from "../../features/experiment/ExperimentDashboardLayout";
import { lazy } from "react";

const Groups = lazy(
  () => import("../../features/experiment/pages/groups/Groups")
);

const GroupsDashboard = lazy(
  () => import("../../features/experiment/pages/groups/Dashboard")
);

const GroupDashboard = lazy(
  () => import("../../features/experiment/pages/groups/group/Dashboard")
);

const GroupAccreditations = lazy(
  () => import("../../features/experiment/pages/groups/group/Accreditations")
);

const GroupWorkerRoles = lazy(
  () => import("../../features/experiment/pages/groups/group/WorkerRoles")
);

const GroupWorkers = lazy(
  () => import("../../features/experiment/pages/groups/group/Workers")
);

const GroupGroupRoles = lazy(
  () => import("../../features/experiment/pages/groups/group/GroupRoles")
);

const GroupWorkerAccreditations = lazy(
  () =>
    import("../../features/experiment/pages/groups/group/worker/Accreditations")
);

const GroupWorkerWorkerRoles = lazy(
  () =>
    import("../../features/experiment/pages/groups/group/worker/WorkerRoles")
);

const GroupWorkerDetails = lazy(
  () => import("../../features/experiment/pages/groups/group/worker/Details")
);

const GroupContractors = lazy(
  () =>
    import(
      "../../features/experiment/pages/groups/group/contractors/Contractors"
    )
);

export const experimentGroupRoutes = [
  {
    path: "experiment",
    element: <ExperimentDashboard />,
    children: [
      {
        path: "groups",
        element: <Navigate to="/experiment/groups/dashboard" />,
      },
      {
        path: "groups/list",
        element: <Groups />,
      },
      {
        path: "groups/dashboard",
        element: <GroupsDashboard />,
      },
      {
        path: "groups/:groupId",
        children: [
          {
            path: "dashboard",
            element: <GroupDashboard />,
          },
          {
            path: "workers",
            element: <GroupWorkers />,
          },
          {
            path: "worker-roles",
            element: <GroupWorkerRoles />,
          },
          {
            path: "accreditations",
            element: <GroupAccreditations />,
          },
          {
            path: "group-roles",
            element: <GroupGroupRoles />,
          },
          {
            path: "contractors",
            element: <GroupContractors />,
          },
          {
            path: "workers/:workerId",
            children: [
              {
                path: "details",
                element: <GroupWorkerDetails />,
              },
              {
                path: "accreditations",
                element: <GroupWorkerAccreditations />,
              },
              {
                path: "worker-roles",
                element: <GroupWorkerWorkerRoles />,
              },
            ],
          },
        ],
      },
    ],
  },
] as RouteObject[];
