import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { uploadFileToFileStorage } from "../../../../services/s3ClientStorageService";
import { LoadingStatus } from "../../../../models/loadingStatus";
import * as accreditationService from "../services/accreditationService";
import { AccreditationWithUser } from "../../../models/accreditation";
import { AxiosError } from "axios";

type ImportAccreditationCsvRequest = {
  orgId: string;
  file: File;
};

export const importAccreditationCsv = createAsyncThunk(
  "accreditation/importAccreditationCsv",
  async (request: ImportAccreditationCsvRequest, { rejectWithValue }) => {
    const importId = nanoid();

    try {
      await uploadFileToFileStorage(
        `${request.orgId}/uploads/${importId}.csv`,
        request.file,
        "text/csv"
      );

      await accreditationService.importAccreditations({
        orgId: request.orgId,
        csvImportId: importId,
      });
    } catch (err: any) {
      let error = err?.message ?? "An error occurred";
      if (err instanceof AxiosError && err?.response?.data.meta.errorMessage) {
        error = err.response.data.meta.errorMessage;
      }
      return rejectWithValue({
        error: error,
      });
    }
  }
);

export type QueryAccreditationsRequest = {
  orgId: string;
  statuses?: string[];
  userId?: string;
};

export const queryAccreditations = createAsyncThunk(
  "accreditation/queryAccreditations",
  async (request: QueryAccreditationsRequest, { rejectWithValue }) => {
    try {
      return (await accreditationService.queryAccreditations(request)).data;
    } catch (err) {
      return rejectWithValue({
        error: "An error occurred",
      });
    }
  }
);

type AccreditationState = {
  status: LoadingStatus;
  accreditations: AccreditationWithUser[];
  error: string | null;
};

const initialState: AccreditationState = {
  status: LoadingStatus.idle,
  accreditations: [],
  error: null,
};

const accreditationSlice = createSlice({
  name: "accreditation",
  initialState,
  reducers: {
    cleanState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importAccreditationCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(importAccreditationCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });
    builder.addCase(importAccreditationCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(queryAccreditations.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(queryAccreditations.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        accreditations: action.payload.data,
      };
    });
    builder.addCase(queryAccreditations.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { cleanState } = accreditationSlice.actions;

export default accreditationSlice.reducer;
