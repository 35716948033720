import { AxiosPromise } from "axios";
import axios from "../../../../utils/axios";
import { ApiResponseMeta } from "../../../../models/apiResponseMeta";
import { Role } from "../../../models/role";
import { QueryRolesRequest } from "../slices/rolesSlice";

type QueryRolesResponse = {
  meta: ApiResponseMeta;
  data: Role[];
};

export async function queryRoles(
  request: QueryRolesRequest
): Promise<AxiosPromise<QueryRolesResponse>> {
  return await axios.post(`accreditations/roles/v1/query`, request);
}
